<template>
  <div class="w-full pt-5 pb-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="
          $router.push({
            name: 'PerformanceSettings',
            query: { activeTab: 'Performance Cycle' },
          })
        "
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />

      <div style="width: 300px; margin-top: 0.1rem">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3">
          Appraisal Cycle Settings
        </h1>
      </div>
      <div class="w-7/12" style="margin-top: 1.2rem">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>

    <div style="height: 100%" class="mt-28" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

    <div class="px-3" v-else>
      <ValidationObserver rules="required" v-slot="{ handleSubmit }">
        <card class="p-4 mt-3">
          <div class="w-full">
            <div class="w-full flex">
              <p class="text-lg flex flex-grow font-bold mb-7">
                Agreement Cycle Settings
              </p>
              <span
                class="cursor-pointer"
                @click="validateEdit()"
                v-if="isEdit"
              >
                <Icon
                  icon-name="icon-edit"
                  class="text-flame inline-block"
                  size="xs"
                />
                <span class="text-base font-semibold pl-2 text-flame">
                  Edit
                </span>
              </span>
            </div>

            <p class="text-base font-semibold mt-2">Perfomance</p>

            <div class="w-full flex flex-col">
              <div class="grid grid-cols-12 gap-6 mt-4 mb-6">
                <div class="col-span-6">
                  <label class="date-label"> Cycle Start Date </label>
                  <div class="date-flex" :class="isEdit ? 'bg-disabled' : ''">
                    <datepicker
                      placeholder="--Select Day & Month--"
                      style="width: 100%; outline: none"
                      input-class="date-input"
                      format="dd/MMMM"
                      v-model="cycleStartDate"
                      :disabled="isEdit"
                    />
                  </div>
                </div>

                <div class="col-span-6">
                  <label class="date-label"> Cycle End Date </label>
                  <div class="date-flex" :class="isEdit ? 'bg-disabled' : ''">
                    <datepicker
                      placeholder="--Select Day & Month--"
                      style="width: 100%; outline: none"
                      input-class="date-input"
                      format="dd/MMMM"
                      @selected="setCycleEndDate($event)"
                      v-model="cycleEndDate"
                      :disabled="isEdit"
                    />
                  </div>
                </div>
              </div>

              <p class="text-base font-semibold mt-1">Contracting</p>

              <div class="grid grid-cols-12 gap-6 mt-4 mb-6">
                <div class="col-span-6">
                  <label class="date-label"> Start Date </label>
                  <div class="date-flex bg-disabled">
                    <datepicker
                      placeholder="--Select Day & Month--"
                      style="width: 100%; outline: none"
                      input-class="date-input"
                      format="dd/MMMM"
                      v-model="agreementStartDate"
                      @selected="setContractStartDate($event)"
                      :disabled="true"
                    />
                  </div>
                </div>

                <div class="col-span-6">
                  <label class="date-label"> End Date </label>
                  <div class="date-flex" :class="isEdit ? 'bg-disabled' : ''">
                    <datepicker
                      placeholder="--Select Day & Month--"
                      style="width: 100%; outline: none"
                      input-class="date-input"
                      format="dd/MMMM"
                      v-model="agreementEndDate"
                      :disabled="isEdit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full mt-10">
            <p class="text-lg font-bold mb-3">Appraisal Cycle Settings</p>

            <div class="w-full flex flex-col">
              <div class="grid grid-cols-12 gap-6 my-6">
                <div class="col-span-4">
                  <CSelect
                    placeholder="Enter"
                    :options="optionsPeriod"
                    v-model="frequency"
                    label="Appraisal Frequency"
                    variant="w-full h-10"
                    class="h-10 mr-2"
                    :disabled="isEdit"
                  />
                </div>
              </div>

              <div class="w-full flex flex-col">
                <ValidationObserver rules="required" v-slot="{ handleSubmit }">
                  <div
                    class="grid grid-cols-12 gap-6 my-10"
                    v-for="(n, index) in appraisal_cycles"
                    :key="index"
                  >
                    <div class="col-span-4" v-if="frequency !== '1'">
                      <div class="flex -mt-1 mb-1 items-center">
                        <span class="text-sm flex flex-grow">
                          {{ appraisal_cycles[index].label }} <span data-v-d5a084b0="" class="text-base font-bold text-razzmataz">*</span>
                        </span>
                        <checkbox
                          checkbox-size="height:16px; width:16px; margin-top: 3px;"
                          label="Use Custom Name"
                          v-if="frequency !== '5'"
                          v-model="appraisal_cycles[index].checkName"
                          :disabled="isEdit"
                        />
                      </div>
                      <c-text
                        placeholder="--Enter Name--"
                        variant="w-full mt-1"
                        class="label"
                        v-model="appraisal_cycles[index].name"
                        :disabled="
                          appraisal_cycles[index].checkName ? false : true
                        "
                        :rules="['required']"
                      />
                    </div>

                    <div
                      :class="frequency === '1' ? 'col-span-6' : 'col-span-4'"
                    >
                      <label class="date-label"> Appraisal Starts </label>
                      <div
                        class="date-flex"
                        :class="isEdit ? 'bg-disabled' : ''"
                      >
                        <datepicker
                          placeholder="--Select Day & Month--"
                          style="width: 100%; outline: none"
                          input-class="date-input"
                          format="dd/MMMM"
                          v-model="appraisal_cycles[index].appraisal_starts"
                          @selected="setStartDates($event, index)"
                          :disabled="isEdit"
                          :disabled-dates="
                            appraisal_cycles[index].startDisabledDates
                          "
                        />
                      </div>
                    </div>

                    <div
                      :class="frequency === '1' ? 'col-span-6' : 'col-span-4'"
                    >
                      <label class="date-label"> Appraisal Ends </label>
                      <div
                        class="date-flex"
                        :class="isEdit ? 'bg-disabled' : ''"
                      >
                        <datepicker
                          placeholder="--Select Day & Month--"
                          style="width: 100%; outline: none"
                          input-class="date-input"
                          format="dd/MMMM"
                          v-model="appraisal_cycles[index].appraisal_ends"
                          @selected="setEndDates($event)"
                          :disabled="isEdit"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    class="text-flame font-semibold flex w-full mt-6 p-2 text-base cursor-pointer"
                    v-if="frequency === '5' && !isEdit"
                    @click="handleSubmit(addAppraisal)"
                  >
                    <icon icon-name="icon-plus" class="mr-2" size="xsm" />
                    Add Another Appraisal
                  </div>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </card>

        <div class="flex mt-5" v-if="!isEdit">
          <div class="flex flex-grow">
            <Button
              class="bg-dynamicBackBtn text-white text-center"
              width="8rem"
              @click="handleSubmit(saveAppraisal)"
              :disabled="disableBtn"
            >
              {{ editAction ? "Update" : "Save" }}
            </Button>
            <Button
              background-color="none"
              class="ml-4 text-darkPurple text-center"
              width="8rem"
              @click="
                $router.push({
                  name: 'PerformanceSettings',
                  query: { activeTab: 'Performance Cycle' },
                })
              "
            >
              Cancel
            </Button>
          </div>
          <div class="flex justify-end">
            <Button
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn text-center"
              width="8rem"
              @click="saveAppraisal('draft')"
              v-if="payload.status === 'draft'"
            >
              Save Draft
            </Button>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Datepicker from "vuejs-datepicker";
import Card from "@/components/Card";
import Checkbox from "@/components/Checkbox";
import Button from "@/components/Button";
import paramsMixin from "@/utilities/paramsMixin";
import timeFormatMixin from "@/utilities/timeFormatMixin";

export default {
  name: "NewCycle",
  mixins: [paramsMixin, timeFormatMixin],
  components: {
    ValidationObserver,
    Card,
    Button,
    CSelect,
    Datepicker,
    BackButton,
    CText,
    Checkbox,
    Breadcrumb,
  },

  data() {
    return {
      loading: true,
      disableBtn: true,
      breadcrumbs: [
        {
          disabled: false,
          text: "Settings",
          href: "settings",
          id: "Settings",
        },
        {
          disabled: false,
          text: "Performance",
          href: "performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Appraisal Cycle",
          href: "appraisal cycle",
          id: "Appraisal Cycle",
        },
      ],
      optionsPeriod: [
        { id: 1, name: "Annual" },
        { id: 2, name: "BiAnnual" },
        { id: 4, name: "Quarterly" },
        { id: 5, name: "Custom" },
      ],
      optionsVal: "1",
      BiAppraisal: [
        {
          value: "Mid-Year Review",
          label: "First Appraisal",
        },
        {
          value: "Final Appraisal",
          label: "Final Appraisal",
        },
      ],
      QAppraisal: [
        {
          value: "Q1 Appraisal",
          label: "First Appraisal",
        },
        {
          value: "Q2 Appraisal",
          label: "Second Appraisal",
        },
        {
          value: "Q3 Appraisal",
          label: "Third Appraisal",
        },
        {
          value: "Q4 Appraisal",
          label: "Final Appraisal",
        },
      ],
      frequency: null,
      payloadFrequency: "",
      disableAppraisal: false,
      appraisal_cycles: [],
      editAction: false,
      cycleStartDate: "",
      cycleEndDate: "",
      agreementStartDate: "",
      agreementEndDate: "",
      isEdit: false,
      cycleSum: "",
      payload: {
        orgId: "",
        appraisal_frequency: "",
        cycle_start_date: "",
        cycle_end_date: "",
        status: "draft",
        agreement_start: "",
        agreement_end: "",
        appraisal_cycles: [],
      },
      cycleDisableDates: {
        from: "",
        to: "",
      },
      validation: "",
      agreementStart: false,
      setPerformanceEndDate: "",
      setContractingStartDate: "",
    };
  },

  watch: {
    frequency(value) {
      this.appraisal_cycles = [];
      const payloadCycles = this.payload.appraisal_cycles;

      if (value !== "5") {
        for (let i = 0; i < value; i++) {
          this.appraisal_cycles.push({
            name:
              this.payloadFrequency === value
                ? payloadCycles[i].name
                : this.getCycleName(Number(value), i),
            label: this.getCycleLabel(Number(value), i),
            checkName: false,
            appraisal_starts:
              this.payloadFrequency === value
                ? this.setDates(payloadCycles[i].appraisal_starts)
                : "",
            appraisal_ends:
              this.payloadFrequency === value
                ? this.setDates(payloadCycles[i].appraisal_ends)
                : "",
            startDisabledDates: {
              from: "",
              to: this.initializeStartDates(i),
            },
            weight:
              this.payloadFrequency === value ? payloadCycles[i].weight : "",
            id: this.payloadFrequency === value ? payloadCycles[i].id : null,
          });
        }
      } else if (value === "5" && this.payloadFrequency === "5") {
        for (let i = 0; i < payloadCycles.length; i++) {
          this.appraisal_cycles.push({
            name:
              this.payloadFrequency === value ? payloadCycles[i].name : null,
            label: this.getCycleLabel(Number(value), 0),
            checkName: true,
            appraisal_starts:
              payloadCycles[i].appraisal_starts === ""
                ? ""
                : this.setDates(payloadCycles[i].appraisal_starts),
            appraisal_ends:
              payloadCycles[i].appraisal_ends === ""
                ? ""
                : this.setDates(payloadCycles[i].appraisal_ends),
            startDisabledDates: {
              from: "",
              to: this.initializeStartDates(i),
            },
            weight: payloadCycles[i].weight,
            id: this.payloadFrequency === value ? payloadCycles[i].id : null,
          });
        }
      } else {
        this.appraisal_cycles.push({
          name: "First Appraisal",
          label: this.getCycleLabel(Number(value), 0),
          checkName: true,
          appraisal_starts: "",
          appraisal_ends: "",
          weight: "",
        });
      }
    },

    cycleStartDate(value) {
      this.agreementStartDate = value;
    },
  },

  methods: {
    setCycleEndDate(value) {
      this.setPerformanceEndDate = value;
    },

    setContractStartDate(value) {
      this.setContractingStartDate = value;
    },

    initializeStartDates(index) {
      let returnDate;

      if (this.frequency === "1") {
        returnDate = new Date(
          this.cycleEndDate.valueOf() + 1000 * 60 * 60 * 24
        );
      } else if (this.frequency === "2") {
        if (index === 0) {
          returnDate = new Date(
            this.agreementStartDate.valueOf() + 1000 * 60 * 60 * 24
          );
        }
        if (index === 1) {
          returnDate = new Date(
            this.cycleEndDate.valueOf() + 1000 * 60 * 60 * 24
          );
        }
      } else if (this.frequency === "4") {
        if ((index === 0) || (index === 1) || (index === 2)) {
          returnDate = new Date(
            this.agreementStartDate.valueOf() + 1000 * 60 * 60 * 24
          );
        }
        if (index === 3) {
          returnDate = new Date(
            this.cycleEndDate.valueOf() + 1000 * 60 * 60 * 24
          );
        }
      }
      return returnDate || "";
    },

    initializeEndDates(index) {
      let returnDate;

      if (this.appraisal_cycles[index]) {
        returnDate = new Date(
          this.appraisal_cycles[index].appraisal_starts.valueOf() + 1000 * 60 * 60 * 24
        );
      }
      return returnDate || "";
    },

    validateEdit() {
      if (this.agreementStart) {
        this.$toasted.error(
          "Agreement cycle is in progress, you can not perform this action now.",
          { duration: 5000 }
        );
      } else {
        this.isEdit = false;
      }
    },
    watchCycleData() {
      if (this.frequency !== "1") {
        this.validation = this.appraisal_cycles.every(
          (item) => item.name && item.appraisal_starts && item.appraisal_ends
        );
      } else {
        this.validation = this.appraisal_cycles.every(
          (item) => item.appraisal_starts && item.appraisal_ends
        );
      }

      if (this.validation) {
        this.disableBtn = false;
      } else if (!this.validation) {
        this.disableBtn = true;
      }
      return {};
    },

    addAppraisal() {
      const index = this.appraisal_cycles.length;
      this.appraisal_cycles.push({
        name: "Another Appraisal",
        label: `Appraisal ${index + 1}`,
        checkName: true,
        appraisal_starts: null,
        appraisal_ends: null,
      });
    },

    getCycleName(type, index) {
      if (type === 2) {
        return this.BiAppraisal[index].value;
      }

      if (type === 4) {
        return this.QAppraisal[index].value;
      }

      return null;
    },

    getCycleLabel(type, index) {
      if (type === 2) {
        return this.BiAppraisal[index].label;
      }

      if (type === 4) {
        return this.QAppraisal[index].label;
      }

      if (type === 5) {
        return `Appraisal ${index + 1}`;
      }

      return null;
    },

    setAppraisalFreq(value) {
      if (value === "") {
        this.frequency = "1";
      } else {
        this.optionsPeriod.filter((freq) => {
          if (value === freq.name) {
            this.frequency = freq.id.toString();
            this.payloadFrequency = freq.id.toString();
          }
          return {};
        });
      }
    },

    saveAppraisal(status) {
      this.payload.appraisal_cycles = [];

      this.optionsPeriod.filter((freq) => {
        if (freq.id === Number(this.frequency)) {
          this.payload.appraisal_frequency = freq.name;
        }
        return {};
      });

      if (!status) {
        this.payload.status = "active";
      } else {
        this.payload.status = status;
      }

      if (this.validation) {
        this.appraisal_cycles.map((cycle) => {
          this.payload.appraisal_cycles.push({
            name: cycle.name !== null ? cycle.name : "Annual Appraisal",
            appraisal_starts: this.formatDate(cycle.appraisal_starts),
            appraisal_ends: this.formatDate(cycle.appraisal_ends),
            cycleId: cycle.id,
          });
          return {};
        });
      }

      this.payload.orgId = this.$orgId;
      this.payload.cycle_start_date = this.formatDate(this.cycleStartDate);
      this.payload.cycle_end_date = this.formatDate(this.cycleEndDate);
      this.payload.agreement_start = this.formatDate(this.agreementStartDate);
      this.payload.agreement_end = this.formatDate(this.agreementEndDate);

      this.$_createAppraisalCycle(this.payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.isEdit = true;
          this.editAction = true;
          this.$router.back();
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, {
            duration: 5000,
          });
        });
    },

    getAppraisalCycle() {
      this.$_getAppraisalCycle(this.appraisalQuery()).then((response) => {
        this.loading = false;
        const appraisal = response.data.AppraisalCycle;
        if (appraisal.length > 0) {
          this.isEdit = true;
          this.editAction = true;
        }

        const agreementStartDate = this.setDates(appraisal[0].agreement_start);
        const agreementEndDate = this.setDates(appraisal[0].agreement_end);

        agreementEndDate.setDate(agreementEndDate.getDate() + 1);

        const today = new Date();

        if (
          today >= agreementStartDate.toISOString() &&
          today <= agreementEndDate.toISOString()
        ) {
          this.agreementStart = true;
        }

        this.payload.status = appraisal[0].status;
        this.cycleStartDate = this.setDates(appraisal[0].cycle_start_date);
        this.cycleEndDate = this.setDates(appraisal[0].cycle_end_date);
        this.agreementStartDate = this.setDates(appraisal[0].agreement_start);
        this.agreementEndDate = this.setDates(appraisal[0].agreement_end);

        if (appraisal[0].cycles.length > 0) {
          this.payload.appraisal_cycles = appraisal[0].cycles;
          this.setAppraisalFreq(appraisal[0].appraisal_frequency);
        } else {
          this.setAppraisalFreq("");
        }

        if (appraisal[0].id !== "") {
          this.payload.appraisalId = appraisal[0].id;
        }

        this.loading = false;
      });
    },
  },

  mounted() {
    this.getAppraisalCycle();
    setInterval(() => {
      this.watchCycleData();
    }, 1000);
  },
};
</script>

<style >
.label label {
  display: none !important;
}
.label .text-razzmataz {
  display: none !important;
}
.label .text-razzmataz {
  display: none;
}
.label .text-razzmataz {
  display: none;
}

.bg-disabled {
  background-color: #eeeeee;
  border-color: transparent;
}
</style>
